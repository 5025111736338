#Parent {
    position: relative;
    float: left;
    border-radius: 10px;
    background-color: #e4ffe5;
    border: solid 3px #e4ffe5;
    min-width: 150px;
    min-height: 100px;
    width: 12vw;
    height: 15vh;
    left: 10vw;
    top: 8vh;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#Heading {
    position: relative;
    font-family: SFProText-Regular;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.41px;
    left: 8pt;
    top: 14pt;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#Value {
    position: relative;
    font-family: SFProDisplay-Medium;
    font-size: 28px;
    letter-spacing: 0.34px;
    left: 8pt;
    top: 30pt;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#arrowUp {
    position: relative;
    float: right;
    font-size: 13px;
    overflow: auto;
    top: 2vh;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#Up {
    color: #4cd964;
}

#Down {
    color: #ff3b30;
    transform: rotate(180deg);
}

#change {
    overflow: hidden;
    font-family: SFProRounded-Regular;
    font-size: 13px;
    color: #8a8a8f;
    position: relative;
    float: right;
    margin-top: 1pt;
    margin-left: 2pt;
    margin-right: 6pt;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}