body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: rgb(188, 188, 188);
  opacity: 1; /* Firefox */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'SFProDisplay-Regular';
  src: local('SFpro'), url(./fonts/SFProDisplay-Regular.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'SFProText-Regular';
  src: local('SFpro'), url(./fonts/SFProText-Regular.ttf) format('truetype');
  font-display: block;
}


@font-face {
  font-family: 'SFProDisplay-Medium';
  src: local('SFpro'), url(./fonts/SFProDisplay-Medium.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'SFProDisplay-Bold';
  src: local('SFpro'), url(./fonts/SFProDisplay-Bold.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'SFProRounded-Regular';
  src: local('SFpro'), url(./fonts/SFProRounded-Regular.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Raleway-Medium';
  src: local('SFpro'), url(./fonts/Raleway-Medium.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Raleway-Bold';
  src: local('SFpro'), url(./fonts/Raleway-Bold.ttf) format('truetype');
  font-display: block;
}

@font-face {
  font-family: 'Raleway-Regular';
  src: local('SFpro'), url(./fonts/Raleway-Regular.ttf) format('truetype');
  font-display: block;
}