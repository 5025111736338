#main{
    width: 100%;
    height: 100%;
}

.heading {
    margin-top: 3%;
    margin-left: 10%;
    font-family: SFProDisplay-Bold;
    font-weight: bold;
    font-style: normal;
    font-size: 40px;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#blob {
    min-width: 300px;
    width: 25vw;
    height: auto;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.toolbar {
    display: inline-block;
    margin-left: 8%;
}

#parentDiv {
    position: relative;
}

#background {
    position: absolute;
    top: 3vh;
}

#mainContainer {
    position: absolute;
}

#child1 {
    position: relative;
    font-family: SFProDisplay-Bold;
    font-size: 22px;
    letter-spacing: 0.35px;
    left: 10vw;
    top: 4.5vh;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#child2 {
    position: relative;
    font-family: SFProDisplay-Bold;
    font-size: 16pt;
    /* left: 10vw; */
    top: 9vh;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    clear: both;

}

#Box1 {
    position: relative;
    float: left;
    border-radius: 10px;
    background-color: #e4ffe5;
    border: solid 3px #e4ffe5;
    min-width: 150px;
    min-height: 100px;
    width: 11.5vw;
    height: 15vh;
    left: 10vw;
    top: 8vh;
    margin: 2px;
}

#Box2 {
    position: relative;
    float: left;
    border-radius: 10px;
    background-color: #ffffff;
    border: solid 3px #f9f9f9;
    min-width: 150px;
    min-height: 100px;
    width: 11.5vw;
    height: 15vh;
    left: 11vw;
    top: 8vh;
    margin: 2px;
}

::-webkit-scrollbar {
    width: .4em;
    height: .4em;
}

::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
}

::-webkit-scrollbar-thumb {
    background-color: #93C3D4;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #23a0ff;
}
