.TableContainer {
    position: absolute;
}

#TableHeading {
    position: relative;
    font-family: SFProDisplay-Bold;
    font-size: 16pt;
    left: 10vw;
    top: 3vh;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

.TeamTable {
    position: relative;
    left: 10vw;
    top: 6vh;
    border-radius: 10px;
    background-color: #ffffff;
    border: solid 3px #f9f9f9;
    min-width: 700px;
    width: 80vw;
    height: auto;
}

.table {
    position: relative;
    font-size: 12pt;
    font-family: Raleway-Bold;
    width: 98%;
    height: 100%;
    position: relative;
    left: 1vw;
    right: 1vw;
    border-collapse: collapse;
    box-sizing: content-box;
}

.table th {
    font-family: Raleway-Medium;
    font-weight: lighter;
    font-size: 10px;
}

table.table tr td {
    border-left: none;
    border-right: none;
    padding-bottom: 30pt;
    vertical-align: center;
}

table.table tr td:first-child {
    position: relative;
    border-right: solid 3px #f9f9f9;
    width: 12vw;
    vertical-align: top;
    min-width: 120px;
}

table.table tr th:first-child {
    position: relative;
    border-right: solid 3px #f9f9f9;
    width: 12vw;
    vertical-align: top;
    min-width: 120px;
}

#touch {
    height: 12px;
    width: 12px;
    background-color: #2388d3;
    border-radius: 50%;
    display: inline-block;
    margin: 2px;
}

#timer {
    height: 12px;
    width: 12px;
    background-color: #2388d34d;
    border-radius: 50%;
    display: inline-block;
    margin: 2px;
}

.index {
    position: relative;
    float: left;
    top: 7vh;
    left: 12vw;
    display: inline-flex;
    align-items: center;
    font-family: SFProDisplay-Regular;
    font-weight: lighter;
    color: grey;
    font-size: 10px;
    padding: 10px;
}