#profile {
    height: 4.5vh;
    width: auto;
    min-height: 30px;
    padding-right: 4px;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}

#container {
    vertical-align: middle;
    position: absolute;
    display: flex;
    /* max-width: 200px; */
}

#children1 {
    position: relative;
}

#children2 {
    position: relative;
    font-size: 12pt;
    font-family: Raleway-Bold;
    float: right;
    padding-left: 5px;
}

#children3 {
    position: relative;
    font-size: 10pt;
    font-family: Raleway-Regular;
    /* float: left; */
}